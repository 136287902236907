import React, { useEffect, useState } from 'react';
import { auth, firestore, storage } from './firebase';
import { setDoc, doc, getDoc, getDocs, collection, query, limit, where } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, getBlob } from 'firebase/storage';
import { Link } from 'react-router-dom';

import Category from './components/Category';
import { get, set } from 'firebase/database';
import { upload } from '@testing-library/user-event/dist/upload';
import { onAuthStateChanged } from 'firebase/auth';

function App() {

    const [imgs, setImgs] = useState([]);
    const [products, setProducts] = useState([]);
    const [search1, setSearch1] = useState('');
    const [search2, setSearch2] = useState('');

    
    const [currency, setCurrency] = useState("USD");
    const [currencyrate, setCurrencyrate] = useState(1380);
    const [currencysymbol, setCurrencysymbol] = useState("$");
    const [currencydigit, setCurrencydigit] = useState(10);


    useEffect(() => {
        
        getCurrencyinfo();
        setSearch1('');
        setSearch2('');
    }, []);

    useEffect(() => {
        if (search1 === '') getProducts();
        else searchProducts();
    }, [search1, search2]);

    useEffect(() => {
        setSearch2('');
    }, [search1]);

    useEffect(() => {
        getProducts();
    }, currency)
    
    const getCurrencyinfo = () => {
        if(auth.currentUser) {
            getDoc(doc(firestore, `users/${auth.currentUser.uid}`)).then((docc) => {
                if(docc.exists()) {
                    const currencytype = docc.data().currency;
                    setCurrency(docc.data().currency);
                    getDoc(doc(firestore, `serverinfo/currency`)).then((docc) => {
                        setCurrencyrate(docc.data()[currencytype].rate);
                        setCurrencysymbol(docc.data()[currencytype].symbol);
                        setCurrencydigit(docc.data()[currencytype].digit);
                        getProducts();
                    })
                }
            });
        }
    }

    const getProducts = async () => {
        const productsCol = query(collection(firestore, 'products'), limit(18));
        const productsSnapshot = await getDocs(productsCol);
        const productsList = productsSnapshot.docs.map(doc => doc.data());
        setProducts(productsList);
    }

    const searchProducts = async () => {
        if(search2 === '') {
            const searchquery = query(collection(firestore, 'products'), where('search', 'array-contains',  search1));
            const productsSnapshot = await getDocs(searchquery);
            const productsList = productsSnapshot.docs.map(doc => doc.data());
            setProducts(productsList);
        } else{
            const searchquery = query(collection(firestore, 'products'), where('search', 'array-contains', search1 + search2));
            const productsSnapshot = await getDocs(searchquery);
            const productsList = productsSnapshot.docs.map(doc => doc.data());
            setProducts(productsList);
        }
    }

    function conditionColor(condition) {
        if (condition == 'S') return '#00ff00';
        else if (condition == 'A') return '#ffff00';
        else if (condition == 'B') return '#ff0000';
    }

    function priceFormat(price) {
        if(price !== undefined) {
            if(price == 1000000000 || price == 0)
                return 'Searching';
            else
                return currencysymbol + (Math.ceil(price / (currencyrate* currencydigit)) * currencydigit).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else{
            return 'Searching';
        }
    }


    const loadimg = async (img, index, array) => {
        try {
            const newURL = "product_img/" + `${img}.webp`;
            const storageRef = ref(storage, newURL);
            await getDownloadURL(storageRef).then((url) => {
                array[index] = url;
                return array;
            });
        } catch (error) {
            console.error("Error loading image:", error);
        }
        setImgs(imgs)
    }

    useEffect(() => {
        let a = [];
        let i = 0;
        products.map((product, index) => {
            loadimg(product.img, index, a).then((url) => {
                i++;
            }).then(() => {
                if(i == products.length || (i % 10) == 0) setImgs(a);
            });
        })
    }, [products])

    const uploaddata = () => {
        console.log('uploading data');
        setDoc(doc(firestore, 'products', '202701'), {
            img: 'product_202701',
            model: '갤럭시탭 S9 FE+',
            price: 150000,
            manufacturer: '삼성',
            category: '갤럭시탭',
            series: '갤럭시탭 S9 FE+',
            capacity: 'none',
            search: '삼성#갤럭시탭S시리즈#S9FE+#갤럭시탭'.split('#'),
        });
    }

    const setkeysindata = () => {
        console.log('setting keys in data');
        const productsCol = collection(firestore, 'products');
        const productsSnapshot = getDocs(productsCol);
        productsSnapshot.then((querySnapshot) => {
            querySnapshot.forEach((docc) => {
                setDoc(doc(firestore, 'products', docc.id), {
                    code: docc.id
                }, { merge: true });
            });
        });
    }

    return (
        <div className="App">
            <Category setsearch1={setSearch1} setsearch2={setSearch2} />
            <div className='product-div-list'>
                {
                    products.map((product, index) => {
                        return (
                            <Link to={`/product/${product.code}`} className='product-div-link'>
                                <div className='product-div' key={product.model}>
                                    <img src={imgs[index]} className='product-div-image' />
                                    <div className='product-div-info'>
                                        <div className='product-div-info-title'>
                                            {product.model}
                                        </div>
                                        <div className='product-div-info-price'>
                                            {
                                                product.price.low !== undefined ?
                                                priceFormat(product.price.low)
                                                : 'Searching'
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default App;
