import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/header.css';
import './css/category.css';
import './css/product.css';
import './css/buy.css';
import './css/buycomponent.css';
import './css/menu.css';
import './App.css';
import Header from './components/header';
import ProductPage from './components/ProductPage';
import BuyPage from './components/BuyPage';
import MenuPage from './components/MenuPage';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className='root'>
    <BrowserRouter>
        <Header />
        <Routes>
            <Route path='/' element={<App />} />
            <Route path='/product/:id' element={<ProductPage />} />
            <Route path='/buy/:id' element={<BuyPage />} />
            <Route path='/menu' element={<MenuPage />} />
        </Routes>
    </BrowserRouter>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
