import React from 'react';
import { auth, firestore, provider, storage} from '../firebase';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, setDoc, deleteDoc, coll } from 'firebase/firestore';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { getStorage, ref, getDownloadURL, listAll } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { set } from 'firebase/database';


function ProductPage() {

    let { id } = useParams();
    const provider = new GoogleAuthProvider();
    const navigate = useNavigate();

    const [product, setProduct] = useState({});
    const [list, setList] = useState([]);
    const [imgurl, setImgurl] = useState('');

    const [showinfo, setShowinfo] = useState(null);
    const [infofiles, setInfofiles] = useState([]);
    const [pricedata, setPricedata] = useState([]);

    const [optioncapacity, setOptioncapacity] = useState('');
    const [optioncondition, setOptioncondition] = useState('Excellent');
    const conditions = ['New','As New', 'Excellent', 'Good']

    const [orderlist, setOrderlist] = useState([])
    const [totalprice, setTotalprice] = useState(0)

    const [currencyloading, setCurrencyloading] = useState(false);
    const [currency, setCurrency] = useState("USD");
    const [currencyrate, setCurrencyrate] = useState(1380);
    const [currencysymbol, setCurrencysymbol] = useState("$");
    const [currencydigit, setCurrencydigit] = useState(10);

    const loadimg = async (img) => {
        try {
            const newURL = "product_img/" + `${img}.webp`;
            const storageRef = ref(storage, newURL);
            await getDownloadURL(storageRef).then((a) => {
                setImgurl(a);
                return a;
            });
        } catch (error) {
            console.error("Error loading image:", error);
        }
    }

    const sortonpricearray = (array) => {
        return array.sort((a, b) => a.price - b.price);
    }

    const orderproduct = async () => {
        if(auth.currentUser) {
            const data = {
                quantity: 1,
                product: product.model,
                capacity: optioncapacity,
                condition: optioncondition,
                id: id,
            }
            const docRef = doc(firestore, `users/${auth.currentUser.uid}/orderlist`, id + optioncapacity + optioncondition);

            getDoc(docRef).then((docc) => {
                if(docc.exists()) {
                    const newdata = docc.data();
                    newdata.quantity += 1;
                    setDoc(docRef, newdata).then(() => {
                        getorderlist();
                    })
                } else {
                    setDoc(docRef, data).then(() => {
                        getorderlist();
                    });
                }
            });

        } else {
            signInWithPopup(auth, provider).then((result) => {
                
            }).catch((error) => {
                console.error(error);
            });
        }
    }

    const getorderlist = async () => {
        if(auth.currentUser) {
            const docSnap = await getDocs(collection(firestore, `users/${auth.currentUser.uid}/orderlist`))
            var subtotal = 0;
            if(currencyloading === false) return;
            const list = docSnap.docs.map(docx => {
                const data = docx.data();
                const pricedoc = doc(firestore, `products/${data.id}`);
                getDoc(pricedoc).then((docc) => {
                    const price = docc.data().price[data.condition][data.capacity];
                    data.price = priceFormat(price);
                    subtotal += (Math.ceil(price / (currencyrate* currencydigit)) * currencydigit) * data.quantity;
                    setTotalprice(subtotal);
                    return data
                });
                return data
            })
            setOrderlist(list);
        }
    }

    
    const priceFormat = (price) => {
        if(price !== undefined) {
            if(price == 1000000000 || price == 0)
                return 'Searching';
            else
                return currencysymbol + (Math.ceil(price / (currencyrate* currencydigit)) * currencydigit).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else{
            return 'Searching';
        }
    }

    const updatequantity = (id, quantity) => {
        const docRef = doc(firestore, `users/${auth.currentUser.uid}/orderlist`, id);
        getDoc(docRef).then((docc) => {
            if(docc.exists()) {
                const newdata = docc.data();
                newdata.quantity = newdata.quantity + quantity;
                if(newdata.quantity < 1) {
                    deleteDoc(docRef).then(() => {
                        getorderlist();
                    });
                }
                else{
                    setDoc(docRef, newdata).then(() => {
                        getorderlist();
                    })
                }
            }
        });
    }

    const getProduct = async () => {
        const docSnap = await getDoc(doc(firestore, 'products', id));
        loadimg(docSnap.data().img);
        setProduct(docSnap.data())
        if(docSnap.data().options !== undefined) {
            setOptioncapacity(docSnap.data().options[0]);
        }
    }
    const getList = async () => {
        const docSnap = await getDocs(collection(firestore, `products/${id}/list`));
        const list = docSnap.docs.map(doc => {
            const data = doc.data();
            return { ...data, id: doc.id }; // doc.id를 title로 추가
          });
        setList(sortonpricearray(list));
    }
    
    const getCurrencyinfo = () => {
        if(auth.currentUser) {
            getDoc(doc(firestore, `users/${auth.currentUser.uid}`)).then((docc) => {
                if(docc.exists()) {
                    const currencytype = docc.data().currency;
                    setCurrency(docc.data().currency);
                    getDoc(doc(firestore, `serverinfo/currency`)).then((docc) => {
                        if(docc.exists()) {
                            setCurrencyrate(docc.data()[currencytype].rate);
                            setCurrencysymbol(docc.data()[currencytype].symbol);
                            setCurrencydigit(docc.data()[currencytype].digit);
                            setCurrencyloading(true);
                        }
                    })
                }
            });
        }
    }

    const makerandomcode = () => {
        let result = new Date().getFullYear().toString();
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < 10; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const make8diditcode = () => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const charactersLength = characters.length;
        for (let i = 0; i < 8; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const requestorder = () => {
        if(auth.currentUser) {
            if(orderlist.length === 0) return;
            const data = {
                orderlist: orderlist,
                price: totalprice,
                currency: currency,
                symbol: currencysymbol,
                userid: auth.currentUser.uid,
                mail: auth.currentUser.email,
                status: 0,
            }
            const code = makerandomcode();
            setDoc(doc(firestore, `requests`, code), data).then(() => {
                setDoc(doc(firestore, `users/${auth.currentUser.uid}/requests`, make8diditcode()), {"code" : code, "date" : new Date(), "price" : totalprice, "currency" : currency, "status" : 0}).then(() => {
                    //remove collection orderlist
                    const orderlistRef = collection(firestore, `users/${auth.currentUser.uid}/orderlist`);
                    getDocs(orderlistRef).then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            deleteDoc(doc.ref);
                        });
                        navigate('/buy/'+ code);
                    });
                });
            });
        } else {
            signInWithPopup(auth, provider).then((result) => {
                    
                }
            ).catch((error) => {
                });
        }
    }




    useEffect(() => {
        getCurrencyinfo();
        getProduct();
        getList();
    }, []);

    useEffect(() => {
        getorderlist();
    }, [currencyloading]);
    
    return (
        <div className='page-div'>
            <div className='product-info-div'>
                <img alt='product' className='product-info-img' src={imgurl} />
                <div className='product-info-text'>
                    {
                        product.options !== undefined ?
                        // object map
                        <div className='product-info-select-div'>
                        {
                            product.options.map((option, index) => {
                                return (
                                    <div className={option == optioncapacity ? 'product-info-select-button-selected' : 'product-info-select-button' } key={index} onClick={()=> setOptioncapacity(option)}>
                                        {option}
                                    </div>
                                );
                            })
                        }
                    </div>
                        : null
                    }
                    {
                        <div className='product-info-select-div'>
                            {
                                conditions.map((condition, index) => {
                                    return (
                                        <div className={condition == optioncondition ? 'product-info-select-button-selected' : 'product-info-select-button' } key={index} onClick={()=> setOptioncondition(condition)}>
                                            {condition}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                    <div className='product-list-item' >
                        <div className='product-list-item-price'>
                            <span>{product.model} {optioncapacity} </span> 
                            <span>{optioncondition}</span>
                        </div>
                        <div className='product-list-item-total'>
                            <div>
                            </div>
                            <span>
                            {
                                product.price !== undefined ?
                                priceFormat(product.price[optioncondition][optioncapacity])
                                : null
                            }</span> 
                        </div>
                    </div>
                    <div className='product-add-button' onClick={() => orderproduct()}>
                        Add to list
                    </div>
                </div>
            </div>
            {
                //orderlist.length > 0 or width > 800
                orderlist.length > 0 || window.innerWidth > 1239 ?
                <div className='product-list-div'>
                    <div className='product-list-title'>
                        Orderlist
                    </div>
                    {
                        orderlist.map((item, index) => {
                            return (
                                <div className='product-list-item' key={index}>
                                    <div className='product-list-item-text'>
                                        <span alt="product-name">{item.product} {item.capacity} </span> 
                                        <span alt="product-condition">{item.condition}</span>
                                    </div>
                                    <div className='product-list-item-text'>
                                        {/* getpricedata(item.id, item.capacity, item.condition) */}
                                        <span alt="product-price">{item.price}</span>
                                        <div className='product-list-quantity'>
                                            <div className='quantity-button' onClick={() => updatequantity(item.id+item.capacity+item.condition, 1)}>
                                                <AiOutlinePlusCircle/>
                                            </div>
                                            {item.quantity}
                                            <div className='quantity-button' onClick={() => updatequantity(item.id+item.capacity+item.condition, -1)}>
                                                <AiOutlineMinusCircle/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                    <div className='product-list-item'>
                        <div className='product-list-item-total'>
                            <div>
                                to
                            </div>
                            <span>{currency} {currencysymbol}{totalprice}</span> 
                        </div>
                    </div>
                    <div className='product-order-button' onClick={() => requestorder()}>
                        Complete Order
                    </div>
                </div> : null
            }
        </div>
    );
}

export default ProductPage;
