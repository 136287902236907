
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useEffect, useState } from 'react';


function Header() {

    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setLoaded(true);
        });
    }, []);

    const gotomenu = () => {
        if (!loaded) return;
        if (auth.currentUser) {
            navigate('/menu');
        } else {
            const provider = new GoogleAuthProvider();
            signInWithPopup(auth, provider).then((result) => {
                navigate('/menu');
            }).catch((error) => {
                console.error(error);
            });
        }

    }

    return (
        <div className='Header'>
            <div className='Header-main'>
                <Link to='/' className="Header-logo-ul">
                        <img alt="logo" className='header-logo' src={`${process.env.PUBLIC_URL}/img/logo.webp`} />
                </Link>
                <div className="Header-menu" onClick={()=> gotomenu()}>
                    <span>
                    ☰
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Header;
