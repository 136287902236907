import React from 'react';
import { auth, firestore, provider, storage } from '../firebase';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, listAll } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { set } from 'firebase/database';
import BuyButton from './BuyButton.js';


function BuyComponent0(id) {

    return (
        <div className='buy-component'>
             Your order will be processed within 24 hours. 
        </div>
    );
}

function BuyComponent1(id) {
    
    const [paymentdata, setPaymentdata] = useState({});

    const paymentbanks = {
        'AED': {},
        'AUD': {},
        'BGN': {},
        'CAD': {},
        'CHF': {},
        'CNY': {},
        'CZK': {},
        'DKK': {},
        'EUR': {},
        'GBP': {},
        'HKD': {},
        'HUF': {},
        'ILS': {},
        'NZD': {},
        'NOK': {},
        'PLN': {},
        'RON': {},
        'SGD': {},
        'SEK': {},
        'TL': {},
        'USD': {},
        'ZAR': {},
    }

    useEffect(() => {
        getDoc(doc(firestore, `requests/${id}`)).then((docc) => {
            if(docc.exists()){
                if(docc.data().paymentdata == undefined) {
                    setPaymentdata({});
                }
            }
        });
    }, []);

    return (
        <div className='buy-component'>
        </div>
    );
}

function BuyComponent2() {

    return (
        <div className='buy-component'>
        </div>
    );
}

function BuyComponent3() {

    return (
        <div className='buy-component'>
        </div>
    );
}

function BuyComponent4() {

    return (
        <div className='buy-component'>
        </div>
    );
}

function BuyComponent5() {

    return (
        <div className='buy-component'>
        </div>
    );
}

function BuyComponent6() {

    return (
        <div className='buy-component'>
        </div>
    );
}

export { BuyComponent0, BuyComponent1, BuyComponent2, BuyComponent3, BuyComponent4, BuyComponent5, BuyComponent6 };
