import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, firestore, provider, storage } from '../firebase';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, listAll } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { set } from 'firebase/database';


function MenuPage() {

    const navigate = useNavigate();
    const [purchase, setPurchase] = useState([]);
    const [currency, setCurrency] = useState("USD");
    const [showcurrency, setShowcurrency] = useState(false);
    const [currencyinfo, setCurrencyinfo] = useState({});

    const processcode = {
        0: 'checking order',
        1: 'order confirmed',
        2: 'shipping',
        3: 'shipped',
        4: 'return requested',
        5: 'return completed',
        6: 'rejected',
    }


    const logout = () => {
        auth.signOut().then(() => {
            navigate('/');
        }).catch((error) => {

        });
    }

    useEffect(() => {
        const getPurchase = async () => {
            if (auth.currentUser) {
                getDoc(doc(firestore, `users/${auth.currentUser.uid}`)).then((docc) => {
                    if(docc.exists()) {
                        setCurrency(docc.data().currency);
                    }
                });
                getDocs(collection(firestore, `users/${auth.currentUser.uid}/requests`)).then((snapshot) => {
                    let temp = [];
                    snapshot.forEach((docc) => {
                        temp.push({id: docc.id, ...docc.data()});
                    });
                    setPurchase(temp);
                });
            }
        }
        getDoc(doc(firestore, `serverinfo/currency`)).then((docc) => {
            if(docc.exists()) {
                setCurrencyinfo(docc.data());
            }
        });
        getPurchase();
    }, []);

    return (
        <div className='App'>
            <div>
                {auth.currentUser ?
                    <div className='menu-user'>
                        <img alt='profile' className='menu-profile' src={auth.currentUser.photoURL} />
                        <div>
                            <div className='menu-name'>{auth.currentUser.email}</div>
                            <div className='menu-logout' onClick={()=> logout()}>Sign out</div>
                        </div>
                    </div>   
                :
                    <div className='menu-user'>Loading user data</div>
                }
            </div>
            <div className='menu-info'>
                <div className='menu-currency' onClick={() => setShowcurrency(!showcurrency)}>{currency}</div>
            </div>
                {
                    showcurrency ?
                    <div className='menu-info'>
                        {
                            Object.keys(currencyinfo).map((key, index) => {
                                return (
                                    <div key={index} className='menu-currency' onClick={() => {
                                        setDoc(doc(firestore, `users/${auth.currentUser.uid}`), {
                                            currency: key
                                        }, { merge: true });
                                        setCurrency(key);
                                        setShowcurrency(false);
                                    }}>{key}</div>
                                )
                            })
                        }
                    </div>
                    : null
                }
            {
                purchase.map((item, index) => {

                    const listdate = new Date(item.date).toLocaleString();

                    return (

                        <Link to={'/buy/' + item.code} key={index} className='link'>
                            <div className='menu-div'>
                                <h1 className='menu-title'>#{item.id}</h1>
                                <div className='menu-price'>{item.currency} {item.symbol}{item.price}</div>
                                <div className='menu-status'>{processcode[item.status]}</div>
                                <div className='menu-date'>{item.date.toDate().toLocaleString()}</div>
                            </div>
                        </Link>
                    )
                })
            }
        </div>
    );
}

export default MenuPage;
