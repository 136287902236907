import React from 'react';
import { auth, firestore, provider, storage } from '../firebase';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, listAll } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BuyComponent0, BuyComponent1, BuyComponent2, BuyComponent3, BuyComponent4, BuyComponent5, BuyComponent6 } from './BuyComponents';
import { set } from 'firebase/database';


function BuyPage() {

    let { id } = useParams();

    const [buycode, setBuycode] = useState(0);
    const [message, setMessage] = useState('');
    const [processarr, setProcessarr] = useState({});

    useEffect(() => {
        checkmessage();
    }, [buycode]);

    const checkmessage = () => {
        var x = '';
        var temp = [];
        getDoc(doc(firestore, `requests/${id}`)).then((docc) => {
            processtype[buycode].map((item, index) => {
                if(docc.data().processed[item] != 0 && x == '') {
                    setMessage(item);
                    x = item;
                }
                if(docc.data().processed[item] == undefined) {
                    temp[item] = 2;
                } else {
                    temp[item] = docc.data().processed[item];
                }
            })
            setProcessarr(temp);
        })
    }

    const processtype = {
        0: ['Checking Order', 'Payment Confirmation'],
    }

    const processlist = {
        'Checking Order': 0,
        'Payment Confirmation': 1,
        'Shipping': 2,
        'Shipped': 3,
        'Return Requested': 4,
        'Return Completed': 5,
        'Rejected': 6,
    }
    
    const processmessage = {
        'Checking Order': 'The order is currently being checked for stock availability',
        'Payment Confirmation': 'The payment has been confirmed and the order is being processed',
        'Shipping': 'The order is being prepared for shipping',
        'Shipped': 'The order has been shipped',
        'Return Requested': 'A return has been requested for the order',
        'Return Completed': 'The return has been completed',
        'Rejected': 'The order has been rejected',
    }
    
    const processcomponent = {
        'Checking Order': (<BuyComponent0 id={id} />),
        'Payment Confirmation': (<BuyComponent1 id={id} />),
        'Shipping': (<BuyComponent2 id={id} />),
        'Shipped': (<BuyComponent3 id={id} />),
        'Return Requested': (<BuyComponent4 id={id} />),
        'Return Completed': (<BuyComponent5 id={id} />),
        'Rejected': (<BuyComponent6 id={id} />),
    }
    
    const getBuy = () => {
        // check code in userid
        if(auth.currentUser){
            getDoc(doc(firestore, `requests/${id}`)).then((docc) => {
                if(docc.exists()) {
                    setBuycode(docc.data().status);
                }
            });
        }
    }

    useEffect(() => {
        getBuy();
    }, []);

    const returnicon = (index) => {
        if(index === 0) return (
            <div className='buy-circle-check'>
                <div className='buy-check1'></div>
                <div className='buy-check2'></div>
            </div>
        )
        if(index === 1) return (
            <div className='buy-circle-stop'>
                <div className='buy-stop1'></div>
                <div className='buy-stop2'></div>
            </div>
        )
        if(index === 2) return (
            <div className='buy-circle-wait'>
                <div className='buy-wait1'></div>
                <div className='buy-wait2'></div>
                <div className='buy-wait3'></div>
            </div>
        )
    }

    const returnui = (index) => {
        if(index === 0) return (
            <div class="success-checkmark">
                <div class="check-icon">
                    <span class="icon-line line-tip"></span>
                    <span class="icon-line line-long"></span>
                    <div class="icon-circle"></div>
                    <div class="icon-fix"></div>
                </div>
            </div> 
        )
        if(index === 1) return (
            <div class="fail-stopsign">
                <div class="stop-icon">
                    <span class="stop-icon-line stop-line-tip"></span>
                    <span class="stop-icon-line stop-line-long"></span>
                    <div class="stop-icon-circle"></div>
                    <div class="stop-icon-fix"></div>
                </div>
            </div> 
        )
    }

    return (
        <div className='App'>
            {returnui(buycode)}
            <h1 className='buy-title'>
                {
                    message === '' ?
                    null : message
                }
            </h1>
            <p className='buy-sub'>
                {processmessage[message]}
            </p>
            <ul className='buy-ul'>
                {
                    Object.keys(processarr).length === 0 ?
                    null :
                    processtype[buycode].map((item, index) => {
                        return (
                            <li>
                                <div className='buy-li' >
                                    {returnicon(processarr[item])}
                                    {item}
                                </div>
                                {processcomponent[item]}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    );
}

export default BuyPage;
