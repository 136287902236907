import { useState } from 'react';

function Category({setsearch1, setsearch2}) {


    const [category, setCategory] = useState('')
    const [subCategory, setSubCategory] = useState('')
    const cartegory1 = ['iPhone']
    const cartegory2 = {
        'iPhone': ['iPhone','Plus', 'Pro', 'Pro Max', 'SE', 'mini'],
        '아이패드': ['iPad', 'Pro', 'Air', 'mini'],
        '맥': ['MacBook Air', 'MacBook Pro', 'Mac', 'mini', 'Studio', 'Pro'],
        '갤럭시': ['S시리즈', 'Z시리즈'],
        '갤럭시탭': ['S시리즈'],
        '애플': ['애플워치', '에어팟', '애플펜슬'],
        '삼성': ['버즈', '갤럭시 워치']
    }


    return (
        <div className='category-div'>
            <ul className='category-ul'>
                {cartegory1.map((ctg) => (
                    category === ctg ?
                    <li key={ctg} className='category-li-selected' onClick={()=>{
                        setCategory('')
                        setSubCategory('')
                        setsearch1('')
                    }} >
                        {ctg}
                    </li> :
                    <li key={ctg} className='category-li' onClick={()=>{
                        setCategory(ctg)
                        setSubCategory('')
                        setsearch1(ctg)
                    }} >
                        {ctg}
                    </li>
                ))}
            </ul>
            {
                category === '' ?
                null :
                <ul className='category-ul'>
                    {cartegory2[category].map((ctg) => (
                        subCategory === ctg ?
                        <li key={ctg} className='category-li-selected' onClick={()=> {
                            setSubCategory('')
                            setsearch2('')
                        }}>
                            {ctg}
                        </li> :
                        <li key={ctg} className='category-li' onClick={()=> {
                            setSubCategory(ctg)
                            setsearch2(ctg)
                        }}>
                            {ctg}
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
}

export default Category;
